/* Global settings */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text */
}

/* App container */
.App {
  text-align: center;
  padding: 2rem;
  background-color: #121212; /* Match the dark theme */
  color: #e0e0e0;
  min-height: 100vh;
}

/* Headings */
h1, h2, h3 {
  color: #ffffff;
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

/* Input fields */
input[type="text"],
input[type="file"],
input[type="number"] {
  padding: 0.5rem;
  margin: 1rem;
  border-radius: 5px;
  border: none;
  background-color: #1e1e1e;
  color: #ffffff;
  font-size: 1rem;
}

input[type="text"]:focus,
input[type="number"]:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 215, 0, 0.8); /* Subtle yellow focus */
}

/* Buttons */
button {
  padding: 0.8rem 1.5rem;
  margin-top: 1.5rem;
  background-color: #3b3b3b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ffa726;
}

/* Bar chart container */
.bar-chart-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

/* Drill scores list */
ul {
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
}

li {
  background-color: #1e1e1e;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  color: #ffa726; /* Highlight score */
}

/* Overlay for break */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 2rem;
  z-index: 1000;
}

/* Drill word */
.drill-word {
  color: #00e5ff; /* Bright cyan to stand out */
  font-size: 2.5rem; /* Larger font size */
  font-weight: bold; /* Bold for emphasis */
  margin: 1rem 0; /* Some spacing around the word */
}

.description-text {
  max-width: 500px;  /* Limits the width of the paragraph */
  margin: 0 auto;    /* Centers the text block */
  padding: 20px;     /* Adds padding around the text */
  line-height: 1.6;  /* Adds space between lines */
  font-size: 16px;   /* Sets a readable font size */
  text-align: justify; /* Justifies the text */
  font-style: italic;
}

